<template>
  <router-link :to="{name: 'cabinet.lead', params: {lead_id: lead.id}}">
    <b-card>
      <b-row>
        <b-col>
          <div class="d-flex align-items-center">
            <p class="font-small-3 font-weight-bold text-muted mb-0 mr-1">
              №{{ lead.displayed_id }}
            </p>
            <span class="text-muted font-small-3 mr-1">{{ lead.created | dateTime }}</span>
            <b-badge
              :variant="statusProps.color"
              class="mr-1"
            >
              <feather-icon
                :icon="statusProps.icon"
                class="mr-50"
              />
              {{ statusProps.label }}
            </b-badge>
          </div>
          <div class="mt-1">
            <p
              v-if="lead.subject"
              class="mb-0 font-medium-1 font-weight-bold text-body"
            >
              {{ lead.subject.name }}, {{ lead.grade.name }}
            </p>
            <p class="mb-0 font-medium-1 font-weight-bold text-body">
              {{ lead.study_purpose }}
            </p>
            <p class="text-muted">
              {{ lead.study_type }}
            </p>

          </div>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <p class="font-small-3 text-muted mb-50">
            Контакты
          </p>
          <div class="font-medium-1 mb-0 text-body">
            <span class="first-letter-capital">{{ lead.parent_name }} </span>
            <span class="text-muted font-small-2"> (заказчик)</span>
          </div>
          <div class="font-medium-1 mb-0 text-body">
            <span class="first-letter-capital">{{ lead.child_name }}  </span>
            <span class="text-muted font-small-2"> (ребенок, {{ lead.grade ? lead.grade.name : 'Класс не указан' }})</span>
          </div>
          <p class="font-medium-1 d-flex mt-50">
            <a
              style="z-index: 44;"
              class="position-relative"
              :href="`tel:${lead.phone}`"
            >
              <b-badge variant="light-primary">
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-50"
                />
                +{{ lead.phone }}
              </b-badge>
            </a>
            <b-badge
              v-if="lead.call_back"
              variant="light-warning"
              class="ml-50"
            >
              <feather-icon
                icon="PhoneCallIcon"
                class="mr-50"
              />
              Требуется звонок
            </b-badge>
          </p>
        </b-col>
        <b-col>
          <p class="font-small-3 text-muted mb-50">
            Детали
          </p>
          <div class="d-flex align-items-center flex-wrap">
            <b-badge
              v-for="rate in lead.rates"
              :key="`rate-${rate.id}`"
              variant="light-warning"
              class="mb-50 mr-50"
              title="Стоимость занятия"
            >
              <feather-icon
                icon="CreditCardIcon"
                class="mr-50"
              />
              {{ rate.name }} руб
            </b-badge>
          </div>
          <p
            v-if="lead.preferred_hours"
            class="text-body"
          >
            {{ lead.preferred_hours }}
          </p>
          <div v-if="lead.comment">
            <p class="mb-0 text-muted font-small-3">
              Комментарий
            </p>
            <p class="text-body">
              {{ lead.comment }}
            </p>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </router-link>
</template>

<script>
import {
  BCard, BBadge, BRow, BCol,
} from 'bootstrap-vue'
import { leadStatusProps } from '../../../helpers'

export default {
  name: 'LeadCard',
  components: {
    BCard,
    BBadge,
    BRow,
    BCol,
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
  },
  computed: {
    statusProps() {
      return leadStatusProps(this.lead.status)
    },
  },
}
</script>

<style scoped>

</style>
